import SEO from '../components/seo';
import React from 'react';
import { isLoggedIn } from '../utils/auth';
import LandingPage from '../components/LandingPage';
import { useContext, useEffect, useState } from 'react';
import { MessageContext } from '../utils/context';

import Dashboard from '../components/Dashboard';

const IndexPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [navOnTop, setNavOnTop] = useState(true);


  useEffect(() => {
    setLoggedIn(isLoggedIn());
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', checkIfTop);
    }
    return () => {
      setLoggedIn(isLoggedIn());
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', checkIfTop);
      }
    };
  });

  const checkIfTop = () => {
    if (window.pageYOffset > 0) {
      setNavOnTop(false);
    } else {
      setNavOnTop(true);
    }
  };

  return (
    <>
      < SEO title="Data-Api " description="solibri data api" lang="en" />
      {loggedIn &&
        <Dashboard />
      }{!loggedIn &&
        <LandingPage />
      }
    </>
  );
};

export default IndexPage;
