import React from 'react';
import Content2 from '../Content';


const PageContainer = () => {
  return (
    <>
      <Content2 />
    </>
  );
};

export default PageContainer;
