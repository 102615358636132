import React from 'react';
import { Box, Typography, Button, Card, CardContent } from '@mui/material';
import { Link, navigate } from 'gatsby';
import { CustomBox, BoxText, CustomPaper, CustomButton, GettingStart } from './styled';
import { Container, Paper, List, ListItem, ListItemText, Divider } from '@mui/material';
import DataApiLogo from '../../../assets/images/hiw-left.png';
import { makeStyles } from '@mui/styles';
import EastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
import { Dashboard } from '@mui/icons-material';

import ButtonGroup from '@mui/material/ButtonGroup';



const useStyles = makeStyles((theme) => ({
  buttonWithBlackBorder: {
    border: '2px solid black',
    color: 'black',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: 'transparent', // Transparent background on hover
    },
  },
}));
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    gap: '50px',
    MarginTop: '10px',
    textAlign: 'center',

  },
  card: {
    display: 'flex',
    flexDirection: 'column', // Make the card and image side by side
    alignItems: 'flex-start', // Align items to the top
    padding: '10px',
    width: '400px',
    height: '400px',
    MarginTop: '10px',
  },
  cardContent: {
    flex: 1, // Allow the card content to expand to fill available space
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    fontFamily: 'var(--font-family-default)',
    fontStyle: 'normal',
    tabSize: 4, // Space between the content and the button
  },
  image: {
    width: '100px', // Set the width of the image
    marginRight: '16px', // Add some margin to separate the image from the card content
  },
  button: {
    color: 'blue',
    fontSize: '2em', // Adjust the font size as needed
  },
  text: {
    marginTop: '10px', // Add some space between the button and text
  },
};

const navigateToDashoard = () => {
  navigate('/Dashboard');
};

const navigateToCatalog = () => {
  navigate('/Catalog');
};


const Content = () => {
  const { t, i18n } = useTranslation();
  const handlegettingstarted = () => {
    navigate('getting-started');

  };
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  };



  const classes = useStyles();
  return (
    <div>

      <CustomBox>
        <div className="mx-auto max-w-2xl pt-20">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">

            <Button sx={{
              border: '1px solid black',
              borderRadius: '25px',
              textTransform: 'none',
              gap: '2px',
              '&:hover': {
                backgroundColor: 'transparent', // Transparent background on hover
              },
            }} variant="outlined">
              <div />  <div className="relative rounded-full px-3 py-1 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                {t('dashboard.explore')}
              </div>
              <Link to='/openapi' style={{ fontWeight: 600 }}>
                <span className="absolute inset-0" aria-hidden="true" />
                {t('dashboard.readmore')}  <span aria-hidden="true">&rarr;</span></Link>
              {/* <EastIcon style={{ fontSize: 'small' }} /> */}
            </Button>
          </div>
        </div>
        <Typography variant="h2" sx={{ fontWeight: 900, paddingTop: '30px', fontFamily: 'system-ui' }}>
          <div className="text-4xl font-bold tracking-tight sm:text-6xl" >
            {t('dashboard.title')}
          </div>
        </Typography>
        <div className="text-4xl font-bold tracking-tight sm:text-6xl">
          <Typography variant="h2" sx={{ fontWeight: 900, fontFamily: 'system-ui' }}>
            {t('dashboard.title2')}

          </Typography>
        </div>
        <br></br>

        <Typography variant="body1">
          {t('dashboard.subtitle')}
        </Typography>

        <Typography variant="body1" sx={{
          py: 2,
          lineHeight: 1,
        }}>

          {t('dashboard.subtitle1')}

        </Typography><div>
          <Box display="flex" justifyContent='space-between' alignItems="center">
            <Link to='/getting-started' className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <CustomButton className="mt-10 flex items-center justify-center gap-x-6"
                onClick={handlegettingstarted}
                variant='outlined'

                sx={{
                  borderColor: '#fff',
                  '&&:hover': {
                    borderColor: '#343a55',
                  },
                  '&&:focus': {
                    borderColor: '#343a55',
                  },
                  width: 'max-content'
                }}

              >

                {t('dashboard.button1')}
              </CustomButton>
            </Link >
            <Link to="/getting-started" style={{ fontWeight: 600 }}> {t('dashboard.button2')}  </Link>
            <EastIcon style={{ fontSize: 'small' }} />
          </Box>
        </div>
      </CustomBox >
    </div>

  );
};

export default Content;