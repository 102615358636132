import { styled } from '@mui/material';
import { Box, Button, Typography, Card, CardContent, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const CustomBox = styled(Box)(({ theme }) => {
  const customTheme = useTheme(); 
  console.log('theme mode', customTheme.palette.mode);
  return {
    minHeight: '80vh',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2), 
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      textAlign: 'center',
    },

    background: customTheme.palette.mode === 'light'
      ? '#fff'
      : '#1f1f1f',
    color: customTheme.palette.text.primary,
  };
});

export const BoxText = styled('div')(({ theme }) => ({
  flex: '1',
  paddingLeft: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    flex: '2',
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const CustomPaper = styled(Paper)(({ theme }) => ({
  elevation: 3,
  padding: '28px',
  borderRadius: '40px',
  height: '300px',
  width: '240px',
  marginLeft: '25%',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.02)',
    fontColor: 'white',
  },
}));

export const GettingStart = styled(Paper)(({ theme }) => ({
  elevation: 3,
  padding: '28px',
  background: 'white',
  borderRadius: '40px',
  height: '350px',
  width: '240px',
  marginLeft: '2%',
  marginBottom: '10%',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.02)',
    fontColor: 'white',
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: '0.9rem',
  textTransform: 'capitalize',
  borderColor: '#14192d',
  paddingLeft: '10px',
  paddingRight: '10px',
  borderRadius: '4px',
  marginRight: '20px',
  color: '#fff',
  width: '100px',
  backgroundColor: '#1976d2',
  '&&:hover': {
    color: '#fff',
    backgroundColor: '#398fc8'
  },
  '&&:focus': {
    color: '#fff',
    backgroundColor: '#398fc8'
  },
}));
